<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as ORG_FORM_TYPES } from './store'
import { ROUTES as ORG_FORM_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../../components/thux-list/ThuxListMixin'

import OrganizationFormEdit from './OrganizationFormEdit.vue'
import OrganizationFormTable from './OrganizationFormTable'
import OrganizationFormCommonFIlters from './OrganizationFormCommonFIlters'

export default {
  name: 'OwnerOrganizationList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': OrganizationFormTable,
    'common-filters': OrganizationFormCommonFIlters,
    'component-detail': OrganizationFormEdit,
    'component-edit': OrganizationFormEdit
  },
  data () {
    return {
      ORG_FORM_ROUTES,
      title: this.$t('Organization Forms'),
      rolePerm: ['organization_organizationform_list'],
      actionEnablePermission: ['organization_organizationform_enable'],
      actionDisablePermission: ['organization_organizationform_disable'],
      searchFields: [
        { key: 'filter__code__icontains', placeholder: this.$t('Code'), type: 'text', col: 6 },
        { key: 'filter__name__icontains', placeholder: this.$t('Name'), type: 'text', col: 6 }
      ]
    }
  },
  computed: {
    ...mapGetters({
      ...ORG_FORM_TYPES.GENERIC.organization.organizationform.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.ORG_FORM_ROUTES.ORGANIZATION_FORM_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('organization_organizationform_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: ORG_FORM_TYPES.GENERIC.organization.organizationform.LIST.MUTATIONS.setSelectedList,
      setSelectAll: ORG_FORM_TYPES.GENERIC.organization.organizationform.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...ORG_FORM_TYPES.GENERIC.organization.organizationform.LIST.ACTIONS
    })
  }
}
</script>
